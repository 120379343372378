<template>
  <aside class="main-sidebar">
    <SideBarMenu v-bind:equipmentId="equipmentId" v-bind:dashboard="dashboard">
    </SideBarMenu>
    <Version />
  </aside>
</template>

<script>
import SideBarMenu from "./sidebar-menu";
import Version from "@/components/version.vue";
export default {
  name: "PageSideBar",
  components: {
    SideBarMenu,
    Version
  },
  props: {
    equipmentId: {
      type: Number,
      required: true
    },
    dashboard: {
      type: Object,
      required: true
    }
  }
};
</script>
<style scoped>
@media (max-width: 767px) {
  .main-sidebar {
    padding-top: 50px;
  }
}
</style>
